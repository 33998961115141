/* html {
  overflow-y: scroll;
} */

/* body {
  overflow: scroll;
}

#root {
  overflow: scroll;
} */

/* edric */
@font-face {
  font-family: Devantor;
  src: url(./assets/fontQuata/Devator.ttf);
}
@font-face {
  font-family: Monda;
  src: url(./assets/fontQuata/Monda-Regular.ttf);
}
@font-face {
  font-family: MondaBold;
  src: url(./assets/fontQuata/Monda-Bold.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(./assets/fontQuata/Montserrat-ExtraBold.ttf);
}

@font-face {
  font-family: MontserratExtra;
  src: url(./assets/fontQuata/Montserrat-ExtraBold.ttf);
}

@font-face {
  font-family: ClashDisplayBold;
  src: url(./assets/fontQuata/ClashDisplay-Bold.ttf);
}
@font-face {
  font-family: Gilroy;
  src: url(./assets/fonts/SVN-Gilroy\ Medium.otf);
}

* {
  font-family: Monda;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 100%;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  width: 5px;
  border-radius: 5px;
  height: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #282727; */
  width: 5px;
  border-radius: 5px;
  height: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #555; */
  width: 5px;
  border-radius: 5px;
  height: 5px;
}

body {
  background: #000;
  margin: 0;
  font-family: Monda, 'Kanit', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  overflow-x: hidden;
}

code {
  font-family: Monda, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.collapse__arrow {
  transition: 0.5s;
}

.collapse__title-active > div {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.collapse__arrow--active {
  transform: rotate(180deg);
}

#container__card-start-earn-0 {
  perspective: 30px;
}

#container__card-start-earn-1 {
  perspective: 30px;
}

#container__card-start-earn-2 {
  perspective: 30px;
}

#inner__card-start-earn-0 {
  transition: transform 0.4s;
  -webkit-transition: transform 0.4s;
}

#inner__card-start-earn-1 {
  transition: transform 0.4s;
  -webkit-transition: transform 0.4s;
}

#inner__card-start-earn-2 {
  transition: transform 0.4s;
  -webkit-transition: transform 0.4s;
}

.color-primary {
  color: #16b979;
}

.text-md {
  font-size: 20px;
}

.cus-font-devantor {
  font-family: Devantor !important;
  letter-spacing: 0.02rem !important;
}

.f-bold {
  font-weight: 700 !important;
}

.f-moda {
  font-family: Monda !important;
}

.white-spacing {
  white-space: nowrap !important;
  letter-spacing: normal !important;
}

.text-dark {
  color: #fff;
}

.cus-over {
  overflow-x: hidden !important;
}

.cus-img-dis {
  margin-top: 22px !important;
  margin-bottom: 0px !important;
}

@media screen and (max-width: 468px) {
  .modal-dis-cus {
    top: 80% !important;
  }
}

.cus-obj {
  object-fit: contain;
}

.cus-nowrap {
  white-space: nowrap;
  padding: 8px 16px;
  background: #262626;
  border: 2px solid #656565;
  box-shadow: inset 0px 11px 13px #000000;
  border-radius: 10px;
}

@media screen and (max-width: 560px) {
  .cus-nowrap {
    font-size: 10px;
  }
}

/*  */

.cus-title-referral {
  font-family: Devantor;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  /* font-feature-settings: 'salt' on, 'liga' off; */
  color: #ffffff;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.cus-title-referral:hover {
  color: #9ade00;
}

.cus-btn-reff {
  background: transparent !important;
  border: 1px solid #fff !important;
  color: #fff;
  transition: all 0.3s ease;
}

.cus-btn-reff:hover {
  color: #9ade00;
  border: 1px solid #9ade00 !important;

}